body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient0 {
  background-color: #FBAB7E;
  background-image: linear-gradient(to right, #FBAB7E 0%, #F7CE68 100%);
}

.gradient1 {
  background: linear-gradient(to right, #ff4e50, #f9d423);
  border: 1px solid white;
}

.gradient2 {
  background: linear-gradient(to right, #5f2c82, #49a09d);
  border: 1px solid white;
}

.gradient3 {
  background-color: #FBAB7E;
  background-image: linear-gradient(to right, #FBAB7E 0%, #F7CE68 100%);
  border: 1px solid white;
}

.statLabel {
  margin-bottom: 0px !important;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
}

.stat {
  color: white;
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: 500;
}
.statContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statLabelContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statLogo{
  font-size: 3em !important;
}